import './App.css';
import './index.css'

// Import containers
import Hero from './container/hero/Hero';


function App() {
  return (
    <div className="App" id='home'>
      <Hero />
    </div>
  );
}

export default App;
