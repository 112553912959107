import React from 'react'
import './Hero.css'

import Navbar from '../../components/navbar/Navbar'
import Header from '../../components/header/Header'

const Hero = () => {
  return (
    <div className="hero__wrapper">
        <Navbar />
        <Header />
    </div>
  )
}

export default Hero