import React from 'react'
import './Header.css'
import QRCode from "qrcode.react";

import SubNav from '../subNav/SubNav'

import NorskTipping from "../../assets/img/NorskTipping.png";

const Header = () => {
  return (
    <div className="header__wrapper">
      <div className="nav__wrapper">
        <div className="nav_left">
          <SubNav />
        </div>
        <div className="nav_right"></div>
      </div>
      <div className="header__container-wrapper">
        <div className="header__container-left">
          <div className="list__container">
            <div className="list-box">
              <div className="list-item">
                <div className="list-header">
                  <h3>Våre maps</h3>
                  <p>Vi tester og tester!</p>
                </div>
              </div>
            </div>
            <div className="list-box">
              <div className="list-item">
                <div className="list-header">
                  <h3>Våre maps</h3>
                  <p>Vi tester og tester!</p>
                </div>
              </div>
            </div>
            <div className="list-box">
              <div className="list-item">
                <div className="list-header">
                  <h3>Våre maps</h3>
                  <p>Vi tester og tester!</p>
                </div>
              </div>
            </div>
            <div className="list-box">
              <div className="list-item">
                <div className="list-header">
                  <h3>Våre maps</h3>
                  <p>Vi tester og tester!</p>
                </div>
              </div>
            </div>
            <div className="list-box">
              <div className="list-item">
                <div className="list-header">
                  <h3>Kult map kompis</h3>
                  <p>Vi tester og tester!</p>
                </div>
              </div>
            </div>
            <div className="list-box">
              <div className="list-item">
                <div className="list-header">
                  <h3>Kult map kompis</h3>
                  <p>Vi tester og tester!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header__container-right">
          <h3>
            Støtt oss med
            <span>Grasrotandelen</span>
          </h3>
          <img src={NorskTipping} alt="" />
          <p>
            Grasrotandelen gir deg som spiller hos Norsk Tipping mulighet til å
            bestemme hvem som skal motta noe av overskuddet til Norsk Tipping.
            Hver gang du spiller går inntil 7 prosent av din spillinnsats til
            den grasrotmottakeren du velger - uten at det går utover din
            innstats, premie eller vinnersjanse. Vi oppfordrer deg til å støtte
            oss i Geniuses Esports
          </p>
          <div className="QRCode">
            <QRCode
              value={
                "https://www.norsk-tipping.no/grasrotandelen/for-lag-og-foreninger/vervebrev/926890271"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header