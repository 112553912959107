import React, { useState, useEffect } from "react";
import { Rotate as Hamburger } from "hamburger-react";
import logo from "../../assets/img/Geniuses_Logo.png";
import "./SubNav.css";
import VisibilitySensor from "react-visibility-sensor";
import testUserImg from "../../assets/img/Profile_img.jpeg";

const Menu = () => (
  <>
    <p className="SubNav_link_animation">
      <a href="#home">Våre maps</a>
    </p>
    <p className="SubNav_link_animation">
      <a href="#link">Bla bla</a>
    </p>
    <p className="SubNav_link_animation">
      <a href="#link">Vi er kule!</a>
    </p>
    <p className="SubNav_link_animation">
      <a href="#link">Geniuses on top</a>
    </p>
  </>
);

const SubNav = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (isVisible) => {
    if (isVisible) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange}>
      <nav>
        <div className={`SubNav-wrapper ${isVisible ? "slide-in-top" : ""}`}>
          <div className="Dencker__SubNav">
            <div className="Dencker__SubNav-links">
              <div className="Dencker__SubNav-links_container">
                <Menu />
              </div>
            </div>
            <div className="Dencker__SubNav-menu">
              <Hamburger
                onToggle={(toggled) => {
                  if (toggled) {
                    setToggleMenu(true);
                  } else {
                    setToggleMenu(false);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="line_vertical-lg"></div>
      </nav>
    </VisibilitySensor>
  );
};

export default SubNav;
