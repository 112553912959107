import React, { useState, useEffect } from "react";
import { Rotate as Hamburger } from "hamburger-react";
import logo from "../../assets/img/Geniuses_Logo.png";
import "./Navbar.css";
import VisibilitySensor from "react-visibility-sensor";
import testUserImg from '../../assets/img/Profile_img.jpeg'

const Menu = () => (
  <>
    <p className="navbar_link_animation">
      <a href="#home">Hjem</a>
    </p>
    <p className="navbar_link_animation">
      <a href="#lan">Lan</a>
    </p>
    <p className="navbar_link_animation">
      <a href="#team">CSGO TEAM</a>
    </p>
    <p className="navbar_link_animation">
      <a href="#contact">Kontakt oss</a>
    </p>
    <p className="navbar_link_animation">
      <a href="#socials">Sosiale medier</a>
    </p>
  </>
);

const Navbar = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (isVisible) => {
    if (isVisible) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange}>
      <div className={`navbar-wrapper ${isVisible ? "slide-in-top" : ""}`}>
        <div className="Dencker__navbar">
          <div className="Dencker__navbar-links">
            <div className="Dencker__navbar-links_logo">
              <img src={logo} alt="logo"></img>
              {/* <a href="#top" className="navbar_brand_animation">
                GENIUSES ESPORTS
              </a> */}
            </div>
            <div className="line_bar-md"></div>
            <div className="Dencker__navbar-links_container">
              <Menu />
            </div>
            <div className="Dencker__navbar-menu_container-links-sign">
              <div className="profile_img">
                <img src={testUserImg} alt="" />
              </div>
              <button type="button" className="btn-md">Sign up</button>
            </div>
          </div>
          <div className="Dencker__navbar-menu">
            <Hamburger
              onToggle={(toggled) => {
                if (toggled) {
                  setToggleMenu(true);
                } else {
                  setToggleMenu(false);
                }
              }}
            />
            {toggleMenu && (
              <div className="Dencker__navbar-menu_container scale-up-center">
                <div className="Dencker__navbar-menu_container-links">
                  <Menu />
                  <div className="Dencker__navbar-menu_container-links-sign">
                    <p>Sign in</p>
                    <button type="button">Sign up</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default Navbar;
